<template>
  <div class="row projects">
    <div class="col-12 section-title active slide-down-d0">
      <h2>Projects</h2>
    </div>
    <div class="col-12 col-lg-8 select-col fade-in-short">
      <div class="custom-select-wrapper">
        <div
          class="custom-select"
          :class="{ opened: isOpen }"
          role="select"
          aria-label="Select a category"
        >
          <span class="custom-select-trigger" @click="isOpen = !isOpen">{{
            categoryLabel
          }}</span>
          <div class="custom-options">
            <span
              class="custom-option"
              v-for="category in categories"
              :key="category.value"
              :value="category.value"
              @click="filterTag(category.value)"
              :class="{ selected: category.value === selectedCategory }"
            >
              {{ category.label }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 pagination-col fade-in-short">
      <div class="pagination" role="pagination" aria-label="pagination">
        <button
          type="button"
          class="btn-prev is-first"
          @click="setPage(page - 1)"
          :aria-disabled="page === 1"
          :disabled="page === 1"
        >
          <i class="bi bi-chevron-left"></i>
        </button>
        <ul>
          <li
            v-for="number in pages"
            :key="number"
            :aria-current="number === page"
            :class="{ active: number === page }"
            @click="setPage(number)"
          >
            {{ number }}
          </li>
        </ul>
        <button
          type="button"
          :aria-disabled="page === pages"
          :disabled="page === pages"
          @click="setPage(page + 1)"
        >
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
    <div
      class="col-12 col-lg-6 col-xl-4 mt-4"
      v-for="(project, index) in projects"
      :key="project.id"
      :data-index="index"
      :class="animClass"
    >
      <project-card :project="project" :index="index" />
    </div>
  </div>
</template>

<script>
import ProjectCard from '@/components/projects/ProjectCard';
import Api from '@/utils/services/Api';

export default {
  name: 'ProjectList',
  components: {
    ProjectCard,
  },
  data() {
    return {
      projects: [],
      isOpen: false,
      categories: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Professional',
          value: 'pro',
        },
        {
          label: 'Personal',
          value: 'perso',
        },
        {
          label: 'School',
          value: 'school',
        },
      ],
      selectedCategory: 'pro',
      categoryLabel: 'Pro',
      pageSize: 6,
      page: 1,
      total: 0,
      animClass: 'fade-in-medium',
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  async created() {
    await this.setPage(1);

    setTimeout(() => {
      this.animClass = '';
    }, 1500);
  },
  methods: {
    async getData(category, limit, page) {
      const { projects, length } = await Api.getAll({
        category,
        limit,
        page,
      });

      this.projects = projects;
      this.total = length;
    },
    async filterTag(tag) {
      this.selectedCategory = tag;
      const chosen = this.categories.find((item) => item.value === tag);
      this.categoryLabel = chosen.label;
      this.isOpen = false;
      await this.getData(tag, this.pageSize, 1);
      this.setPage(1);
    },
    async setPage(val) {
      this.page = val;
      await this.getData(this.selectedCategory, this.pageSize, val)
    },
  },
};
</script>

<style lang="scss">
.projects {
  margin-top: $sp-2x;
  .pagination-col {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
