<template>
  <project-list />
</template>

<script>
import ProjectList from '@/components/projects/ProjectList';
export default {
  name: 'Projects',
  components: {
    ProjectList,
  },
};
</script>